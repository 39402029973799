.page-header.navbar .menu-toggler.sidebar-toggler {
  float: none;
  margin: 23px auto auto; }

.btn-upload {
  height: 200px; }

.pagination-fotos-colecao {
  margin: 0 0 0 0 !important; }

@media (min-width: 992px) {
  .modal-lg {
    width: 960px !important; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 960px !important; } }

@media (min-width: 999px) {
  .menu-fixed {
    position: fixed;
    z-index: 1000;
    width: 100%;
    max-width: 195px; } }

.url {
  word-break: break-all; }

.badge-status {
  font-size: 13px !important;
  height: 24px !important;
  min-width: 24px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  color: #fff; }
  .badge-status--pending-review {
    background-color: #fbc531 !important; }
  .badge-status--waiting-changes {
    background-color: #e67e22 !important; }
  .badge-status--approved {
    background-color: #05c46b !important; }
  .badge-status--expired {
    background-color: #e43a45 !important; }

.swal-title {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 24px; }

.swal-text {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 14px; }

.swal-button {
  font-weight: 400;
  font-size: 14px; }
  .swal-button.warning {
    background-color: #f8bb86; }
  .swal-button.good {
    background-color: #32c5d2; }

.select-date {
  float: left;
  padding: 3px 0;
  margin-bottom: 15px; }
  .select-date .s-date {
    padding: 4px 10px;
    margin: 0;
    height: 33;
    font-weight: 400;
    border-radius: 3px 0 0 3px;
    border: 1px solid #1c84c6;
    cursor: pointer; }
  .select-date .s-icon {
    border-radius: 0 3px 3px 0;
    padding: 4px 10px;
    margin-left: -5px;
    font-size: 14;
    cursor: pointer;
    background-color: #1c84c6;
    color: #fff;
    border: 1px solid #1c84c6;
    font-weight: 300; }
  .select-date .s-datepicker {
    position: absolute;
    display: none;
    background-color: #fff;
    z-index: 100; }
    .select-date .s-datepicker.show {
      display: block; }
    .select-date .s-datepicker div {
      outline: 0 none; }
    .select-date .s-datepicker .DayPicker-Week {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee; }
    .select-date .s-datepicker .DayPicker-Day {
      border-left: 1px solid #eee;
      border-right: 1px solid #eee; }
    .select-date .s-datepicker .DayPicker-wrapper {
      border: 1px solid #eee; }
    .select-date .s-datepicker .DayPicker-Day--today:not(.DayPicker-Day--selected) {
      color: #17C4BB; }
    .select-date .s-datepicker .DayPicker-Caption > div {
      color: #17C4BB;
      font-weight: 600 !important;
      font-size: 14px; }
    .select-date .s-datepicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: #17C4BB;
      font-weight: 600;
      border-radius: 0; }

.watermark-position-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.25);
  background-image: url("../../img/watermark-sample.jpg");
  background-size: cover; }

.watermark-position-grid .position-cell {
  box-sizing: border-box;
  width: 33%;
  margin: 0;
  padding: 15px; }

.watermark-position-grid .position-cell label {
  margin: 0;
  width: 100%;
  height: 10vmin;
  background: rgba(0, 0, 0, 0.35);
  color: rgba(0, 0, 0, 0);
  cursor: pointer; }

.watermark-position-grid .position-cell input {
  display: none; }

.watermark-position-grid .position-cell input:checked + label {
  background: rgba(50, 197, 210, 0.85); }

#lightboxBackdrop {
  z-index: 9999; }
